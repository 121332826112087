.main-game {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    height: 100%;
}

.timer {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 14px;
}

.left-panel {
    display: grid !important;
    grid-template-rows: min-content auto auto;
    grid-gap: 8px;
}

.right-panel {
    display: grid !important;
    grid-template-rows: auto;
    grid-gap: 8px;

    .chat-section {
        display: grid;
        grid-template-rows: min-content auto min-content;
        height: 100%;
    }

    .internal-use-box {
        input {
            text-align: center;
            &::placeholder {
                color: blue;
            }
        }
    }

    .input-box {
        display: grid;
        grid-template-columns: auto min-content;
        input {
            &::placeholder {
                color: blue;
            }
        }
    }
}

.gif-panel {
    position: relative;
    box-sizing: border-box;
    padding: 2px;
    font-size: 1rem;
    border-style: solid;
    border-width: 2px;
    border-left-color: #848584;
    border-top-color: #848584;
    border-right-color: #fefefe;
    border-bottom-color: #fefefe;
    line-height: 1.5;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    min-height: 35px;
    background: #ffffff;
    height: 100%;
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        border-style: solid;
        border-width: 2px;
        border-left-color: #0a0a0a;
        border-top-color: #0a0a0a;
        border-right-color: #dfdfdf;
        border-bottom-color: #dfdfdf;
        pointer-events: none;
        box-shadow: inset 2px 2px 3px rgba(0,0,0,0.2);
    }

    &.no-before {
        &::before {
            content: none;
        }
    }
    .content {
        display: block;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        outline: none;
        border: none;
        background: none;
        font-size: 1rem;
        min-height: 27px;
        font-family: inherit;
        color: #0a0a0a;
        padding: 8px;
        resize: none;
    }
}

.chatlog {
    padding: 1rem;
}

a {
    color: blue !important;
}

.you {
    text-align: right;
}
