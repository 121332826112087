.close-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: -1px;
    margin-top: -1px;
    transform: rotateZ(45deg);
    position: relative;
    &:before,
    &:after {
      content: '';
      position: absolute;
      background: #000;
    }
    &:before {
      height: 100%;
      width: 3px;
      left: 50%;
      transform: translateX(-50%);
    }
    &:after {
      height: 3px;
      width: 100%;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
    }
}

.window-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

body {
    background: teal;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.window {
  width: 1000px;
  height: 600px;

  @media (max-width: 1000px) {
    width: 360px;
    height: 1070px;
    .main-game {
      grid-template-columns: 1fr;
      .gif-panel {
        height: 180px;
      }
    }
  }
}
